export enum SCREEN_IDS {
  Login = 177,
  Wall = 110,
  BumpsSlider = 187,
  ProPreviewProfile = 188,
  Search = 111,
  Filters = 186,
  SearchAlerts = 184,
  MyItemDetail = 114,
  ItemDetail = 115,
  UploadRealEstate = 164,
  UploadCar = 106,
  UploadCG = 160,
  EditItem = 162,
  Profile = 29,
  MyProfile = 112,
  EditProfile = 148,
  MyZone = 140,
  Upload = 41,
  Chat = 27,
  ShareItemPopUp = 189,
  ItemDetailRecommendationSlider = 190,
  SubscriptionManagement = 191,
  SubscriptionBenefits = 192,
  SeoWall = 193,
  MyFavoriteItemsSection = 194,
  ChatInbox = 195,
  KYCTutorial = 196,
  KYCBankAccountInfo = 197,
  KYCIdentityVerification = 198,
  KYCUploadIdentityVerification = 199,
  KYCDocumentationType = 200,
  KYCReviewDocumentationImage = 201,
  KYCVerifyingIdentity = 202,
  ShippingMenu = 203,
  ShippingInbox = 204,
  TransactionTimeline = 205,
  ProfileSubscription = 206,
  Subscription = 207,
  SubscriptionPaymentSuccess = 208,
  TransactionPayScreen = 209,
  AcceptOffer = 210,
  ItemToBumpList = 211,
  ItemReactivation = 212,
  BumpItem = 213,
  MyFavoriteProfilesSection = 214,
  OtherReviewsSection = 215,
  WebHome = 216,
  SavedSearchesNewVsOld = 217,
  NotificationCenter = 218,
  MyCatalog = 219,
  EditCardTransactionPay = 220,
  RegisterForm = 221,
  RegisterVerification = 222,
  RegisterHelpCenter = 223,
  MySavedSearchesSection = 224,
  CustomNative = 225,
  Banner = 226,
  OfficeToOfficeMap = 227,
  Messages = 228,
  BannedUserChatPopUp = 229,
  ProSubscriptionLimitPopup = 230,
  HashtagsAllUpload = 231,
  HashtagsSuggestedUpload = 232,
  MyProfileMenu = 233,
  OwnReviewsSection = 234,
  MyWallet = 235,
  BankTransferAmount = 236,
  BankTransferConfirm = 237,
  Checkout = 238,
  MySoldItems = 239,
  MyCatalogInactiveSection = 240,
  SubscriptionTier = 241,
  MyBankDetails = 242,
  ProAdvantagesPopup = 243,
  TermsCommunicationsConsent = 244,
  EditAddress = 245,
  EditCardSubscriptionPay = 246,
  ShippingReactivationPopup = 247,
  VerificationsAndSecurityScreen = 248,
  EmailVerificationScreen = 249,
  SubscriptionSummary = 250,
  ResetPassword = 251,
  ResetPasswordError = 252,
  SubscriptionCategories = 253,
  WalletMovementsHistory = 254,
  WalletIncomingMovementsHistory = 255,
  WalletOutgoingMovementsHistory = 256,
  ProSubscriptionExpiredItemsPopup = 257,
  AffiliationLanding = 258,
  ReserveItemInfo = 259,
  ReserveItemTimeframes = 260,
  ReserveItemPayment = 261,
  ReserveItemFeedback = 262,
  ChangeEmail = 263,
  ActualizationTermsCommunicationsConsent = 264,
  SuggestedUpdateAppVersionPopup = 265,
  RequiredUpdateAppVersionPopup = 266,
  WallRecommendationSliderFeedback = 267,
  TRACER = 268,
  OnboardingPhoneVerification = 269,
  PhoneVerificationScreen = 270,
  LoginBuy = 271,
  LoginYourWay = 272,
  LoginShipping = 273,
  LoginSafety = 274,
  TransactionsScreen = 275,
  P2PPaymentAmount = 276,
  P2PQRCode = 277,
  P2PPaymentSuccessBuyer = 278,
  P2PScanQR = 279,
  P2PPaymentSuccessSeller = 280,
  P2PPaymentError = 281,
  P2PManualCode = 282,
  '3DSPopup' = 283,
  LoginVerificationSent = 284,
  LoginVerificationSuccessful = 285,
  LoginVerificationError = 286,
  P2PItems = 287,
  TracerError = 288,
  SelectBuyer = 289,
  ReviewForBuyer = 290,
  ReviewForSeller = 291,
  EditWeight = 292,
  MGMHost = 293,
  MGMGuestLink = 295,
  OnboardingIntentionOptions = 296,
  OnboardingBuyer = 297,
  OnboardingSeller = 298,
  P2PPaymentOffer = 299,
  '2FAConfiguration' = 300,
  ActivateShippingToggle = 302,
  SelectShippingWeight = 303,
  KYCSelectImage = 304,
  MyInProgressPurchases = 305,
  MyCompletedPurchases = 306,
  MyInProgressSales = 307,
  MyCompletedSales = 308,
  MyShippingTransactions = 309,
  MyShippingAddress = 310,
  CheckoutLogistics = 311,
  CheckoutPayment = 312,
  CheckoutSummary = 313,
  AccountDeletion = 314,
  MGMHostSuggested = 315,
  ActivateShippingToggleV2 = 316,
  ShippingFAQs = 317,
  BumpItemPurchaseType = 318,
  ProBumpItemPurchaseOption = 319,
  NotificationSettings = 320,
  LoginRegisterMessage = 321,
  LeavePhoneNumberPRO = 322,
  MyFavoriteSearchesSection = 323,
  BumpCheckout = 324,
  SelfServiceWebview = 325,
  TermsCommunicationsConsentUpdate = 326,
  PurchaseDeliveryMethod = 327,
  P2PDeliveryOnboarding = 328,
  P2PPaymentMethod = 329,
  CarrierOfficeMap = 330,
  StatsSection = 331,
  SettingsSection = 332,
  OnBoardingCountryInfo = 333,
  OnBoardingCountrySelection = 334,
  NotSecurePassword = 335,
  RepublishItem = 336,
  P2PCashOutPopUp = 337,
  BulkyUploadDetection = 338,
  BulkyUploadToggle = 339,
  BulkyUploadMeasures = 340,
  BulkyUploadNotAvailableMessage = 341,
  BulkyAvailabilityScreen = 342,
  PushPriming = 343,
  TopUpWalletSelectAmount = 344,
  TopUpWalletSuccess = 345,
  TopUpWalletSummary = 346,
  CollectionResults = 347,
  ActivateFreeShippingToggle = 348,
  EditToggle = 349,
  F2FPayInAdvance = 350,
  '2FABankAccountApproved' = 351,
  '2FABankAccountRefused' = 352,
  '2FABankAccountExpired' = 353,
  OtpScreen = 354,
  CheckoutInitializationError = 355,
  F2FPayInAdvanceSmokeTest = 356,
  UnloggedChat = 357,
  ProDiscountPopup = 358,
  ProOnboardingLanding = 359,
  F2FPaymentsSummary = 360,
  CatalogManager = 361,
  DisputeSelfService = 362,
  DisputeResolutionCenter = 363,
  F2FInstantCashOutPopUp = 364,
  F2FInstantCashOutConfirmation = 365,
  F2FInstantCashOutSurvey = 366,
  ProfileAccountType = 367,
  TermsCommunicationParticular = 368,
  TermsCommunicationProfessional = 369,
  WallapopFAQs = 370,
  CheckoutNonShippableLogistics = 371,
  ProUnsubscribePopup = 372,
  EditBankCard = 373,
  ProAwarenessMyCatalogPopUp = 374,
  BumpUploadPopUp = 375,
  BumpCampaignPopUp = 376,
  OnboardingValueProp = 377,
  ValuePropSeller = 378,
  ValuePropBuyer = 379,
  ValuePropNotSure = 380,
  LockedPerkEditDiscount = 381,
  LockedPerkEditFreeShipping = 382,
  ProUploadBanner = 383,
  ProAwarenessAfterBumpPopup = 384,
  ProAwarenessReserveItemPopup = 385,
  ProAwarenessChatBanner = 386,
  ProAwarenessStatsBanner = 387,
  ProAwarenessProfileBanner = 388,
  ProAwarenessSalesBanner = 389,
  WallapopProtectTooltip = 390,
  LocationPermissions = 391,
  TopUpWalletPayment = 392,
  ProFakeDoorTestPopup = 393,
  EditAddressPopUp = 394,
  ItemStatistics = 395,
  '3DSView' = 396,
  RejectionReasonsPopUp = 397,
  CancellationReasonsPopUp = 398,
  PostPurchaseRecommendations = 399,
  ShippingRequest = 400,
  ShippingDropOffMethods = 401,
  ProAwarenessWallBanner = 402,
  OwnProSection = 403,
  Dac7FormScreen = 404,
  Dac7ReportScreen = 405,
  LockedPerkStock = 406,
  ItemSoldSelectBuyer = 407,
  ItemSoldReviewBuyer = 408,
  ItemSoldOutsideConfirmationPopUp = 409,
  OnGoingSalePopUp = 410,
  F2FRequest = 411,
  '3DSRetryPaymentPopUp' = 412,
  ProEditItemPopup = 413,
  NoNewCollectionLanding = 414,
  ProDeleteItemPopup = 415,
  PersonalImpactStats = 416,
  ExternalLinkPopup = 417,
  SharingPersonalDataPopup = 418,
  VacationMode = 419,
  ClaimForm = 420,
  HomeContentError = 421,
  ProContactForm = 422,
  EmailPostPurchase = 423,
  HelpSectionProfile = 424,
  AddItemUnitsEdit = 425,
  AddItemUnitsSaleStatus = 426,
  CheckoutAbandonmentReasonsPopUp = 427,
  RefundMethod = 428,
  ChatBot = 429,
  ISBNScan = 430,
  ISBNManualInput = 431,
  ISBNNotFound = 432,
  ISBNDigitalBook = 433,
  ISBNConfirmation = 434,
  DynamicPageCategoryPage = 435,
  DynamicPageCollectionPage = 436,
  OfferEditor = 437,
  OfferTrackingStatus = 438,
  UploadAttributesLoading = 439,
  UploadPhotoScreen = 440,
  BundleCreation = 441,
  BundleItems = 442,
  CreateNewPassword = 443,
  ResetPasswordEmailSent = 444,
}
