import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, filter, Observable } from 'rxjs';
import { LoadExternalLibsService } from '@core/load-external-libs/load-external-libs.service';
import { Google } from '@core/google/one-tap-login/google.interface';
import { environment } from '@environments/environment';
import { WINDOW_TOKEN } from '@core/window/window.token';
import { AppStatusService } from '@core/app-status/app-status.service';

const ONE_TAP_LIB_SOURCE = 'https://accounts.google.com/gsi/client';

@Injectable()
export class OneTapLoginService {
  private readonly oneTapReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private loadExternalLibService: LoadExternalLibsService,
    private appStatusService: AppStatusService,
    @Inject(WINDOW_TOKEN) private window: Window,
  ) {}

  get oneTapLibReady$(): Observable<boolean> {
    return this.oneTapReady$.asObservable();
  }

  get google(): Google {
    return this.window['google'];
  }

  public init(): void {
    this.loadExternalLibService.loadScriptBySource(ONE_TAP_LIB_SOURCE).subscribe({
      next: () => {
        this.listenToOneTapInit(this.responseGoogle);
        this.oneTapReady$.next(true);
      },
      error: (error) => {
        this.appStatusService.addError('[One Tap Service]: Error while loading Google Script', error);
      },
    });
  }

  private listenToOneTapInit(callback): void {
    this.oneTapLibReady$.pipe(filter((initialized) => !!initialized)).subscribe(() => {
      if (typeof this.google === 'undefined' || !this.google.accounts || !this.google.accounts.id) {
        return;
      }
      this.google.accounts.id.initialize({
        client_id: environment.googleClientId,
        callback,
        use_fedcm_for_prompt: true,
        use_fedcm_for_button: true,
      });

      this.google.accounts.id.prompt();
    });
  }

  private responseGoogle(response): void {
    const tokenId = response.credential;

    window.location.href = `/login/google/${tokenId}?redirectUrl=${window.location.href}`;
  }
}
