export enum ANALYTICS_EVENT_NAMES {
  CancelSearch = 'Cancel Search',
  ClickAbandonCheckout = 'Click Abandon Checkout',
  ClickAcceptOffer = 'Click Accept Offer',
  ClickActionTappedTransactionalTimeline = 'Click Action Tapped Transactional Timeline',
  ClickActivateShipping = 'Click Activate Shipping',
  ClickAddBundleItem = 'Click Add Bundle Item',
  ClickAddEditAddress = 'Click Add Edit Address',
  ClickAddEditBankAccount = 'Click Add Edit Bank Account',
  ClickAddEditCard = 'Click Add Edit Card',
  ClickAdditionalServicesUpload = 'Click Additional Services Upload',
  ClickBackButton = 'Click Back Button',
  ClickBackEditCard = 'Click Back Edit Card',
  ClickBankDetails = 'Click Bank Details',
  ClickBannedUserChatPopUpCloseButton = 'Click Banned User Chat Pop Up Close Button',
  ClickBannedUserChatPopUpExitButton = 'Click Banned User Chat Pop Up Exit Button',
  ClickRefurbishConditionBanner = 'Click Refurbish Condition Banner',
  ClickSustainabilityBanner = 'Click Sustainability Banner',
  ClickBlockedWalletBanner = 'Click Blocked Wallet Banner',
  ClickBreadcrumb = 'Click Breadcrumb',
  ClickBulkyAvailabilityWarn = 'Click Bulky Availability Warn',
  ClickBulkyItemBanner = 'Click Bulky Item Banner',
  ClickBumpFeaturedSliderWall = 'Click Bump Featured Slider Wall',
  ClickBumpItemCatalog = 'Click Bump Item Catalog',
  ClickBumpItemDetail = 'Click Bump Item Detail',
  ClickBumpItemListed = 'Click Bump Item Listed',
  ClickBumpItemReactivation = 'Click Bump Item Reactivation',
  ClickBundleItems = 'Click Bundle Items',
  ClickBuyNow = 'Click Buy Now',
  ClickBuy = 'Click Buy',
  ClickCarrierOfficeDirections = 'Click Carrier Office Directions',
  ClickCarrierOfficeFilter = 'Click Carrier Office Filter',
  ClickCarrierOfficePoints = 'Click Carrier Office Points',
  ClickCatalogManagement = 'Click Catalog Management',
  ClickCategoryBubble = 'Click Category Bubble',
  ClickCategory = 'Click Category',
  ClickChatButton = 'Click Chat Button',
  ClickCloseChatbot = 'Click Close Chatbot',
  ClickCollection = 'Click Collection',
  ClickComponentTab = 'Click Component Tab',
  ClickConfigurationAdditionalServicesUpload = 'Click Configuration Additional Services Upload',
  ClickConfirmAdditionalServicesUpload = 'Click Confirm Additional Services Upload',
  ClickConfirmBundleCreation = 'Click Confirm Bundle Creation',
  ClickConfirmCancelF2FPaymentSeller = 'Click Confirm Cancel F2F Payment Seller',
  ClickConfirmDeliveryMethod = 'Click Confirm Delivery Method',
  ClickConfirmF2FPaymentDeliveryBuyer = 'Click Confirm F2F Payment Delivery Buyer',
  ClickConfirmF2FPaymentDeliverySeller = 'Click Confirm F2F Payment Delivery Seller',
  ClickConfirmF2FPaymentMoment = 'Click Confirm F2F Payment Moment',
  ClickConfirmF2FPaymentSummary = 'Click Confirm F2F Payment Summary',
  ClickConfirmP2PPaymentMethod = 'Click Confirm P2P Payment Method',
  ClickConfirmPaymentMethod = 'Click Confirm Payment Method',
  ClickConfirmSubscriptionSummary = 'Click Confirm Subscription Summary',
  ClickContinue3DS = 'Click Continue 3DS',
  ClickContinueInstantCashoutToggleOn = 'Click Continue Instant Cashout Toggle On',
  ClickContinueReserveInfo = 'Click Continue Reserve Info',
  ClickContinueUnloggedChat = 'Click Continue Unlogged Chat',
  ClickCreateBundle = 'Click Create Bundle',
  ClickCreateDisputeNextStep = 'Click Create Dispute Next Step',
  ClickCreateDisputePreviousStep = 'Click Create Dispute Previous Step',
  ClickCallToAction = 'Click Call To Action',
  ClickCTA = 'Click CTA',
  ClickDetourComponentValue = 'Click Detour Component Value',
  ClickDisputeReason = 'Click Dispute Reason',
  ClickDownloadApp = 'Click Download App',
  ClickDrawerNavigation = 'Click Drawer Navigation',
  ClickEditPassword = 'Click Edit Password',
  ClickEditPaymentMethod = 'Click Edit Payment Method',
  ClickEditProField = 'Click Edit Pro Field',
  ClickEditShippingCheckoutPreferences = 'Click Edit Shipping Checkout Preferences',
  ClickEditShippingToggle = 'Click Edit Shipping Toggle',
  ClickEditVacationMode = 'Click Edit Vacation Mode',
  ClickExitPage = 'Click Exit Page',
  ClickExternalLink = 'Click External Link',
  ClickF2FMoreInfo = 'Click F2F More Info',
  ClickF2FPayInAdvanceNotifyMe = 'Click F2F Pay In Advance Notify Me',
  ClickF2FPayInAdvance = 'Click F2F Pay In Advance',
  ClickF2FPushNotification = 'Click F2F Push Notification',
  ClickF2FSendPushNotification = 'Click F2F Send Push Notification',
  ClickFAQTransactional = 'Click FAQ Transactional',
  ClickFavoriteSearch = 'Click Favorite Search',
  ClickFavorites = 'Click Favorites',
  ClickFirstPartyAd = 'Click First Party Ad',
  ClickFooterLink = 'Click Footer Link',
  ClickGoBackFromViewPersonalImpactStats = 'Click Go Back From View Personal Impact Stats',
  ClickHelpItemCondition = 'Click Help Item Condition',
  ClickHelpRefurbish = 'Click Help Refurbish',
  ClickHelpTransactional = 'Click Help Transactional',
  ClickHelpWallet = 'Click Help Wallet',
  ClickHelp = 'Click Help',
  ClickInfo = 'Click Info',
  ClickInformItemMeasures = 'Click Inform Item Measures',
  ClickInformWeight = 'Click Inform Weight',
  ClickInstallationAdditionalServicesUpload = 'Click Installation Additional Services Upload',
  ClickInstantCashoutNotifyMe = 'Click Instant Cashout Notify Me',
  ClickItemBannerShipping = 'Click Item Banner Shipping',
  ClickItemCard = 'Click Item Card',
  ClickItemCategoryUpload = 'Click Item Category Upload',
  ClickItemMap = 'Click Item Map',
  ClickItemReactivation = 'Click Item Reactivation',
  ClickItemSubcategoryFieldUpload = 'Click Item Subcategory Field Upload',
  ClickItemSubcategoryUpload = 'Click Item Subcategory Upload',
  ClickItemVisualizeOptions = 'Click Item Visualize Options',
  ClickItemWalletMovements = 'Click Item Wallet Movements',
  ClickKeyboardSearchButton = 'Click Keyboard Search Button',
  ClickKYCCloseTutorial = 'Click KYC Close Tutorial',
  ClickKYCConfirmBankAccountInfo = 'Click KYC Confirm Bank Account Info',
  ClickKYCFinishIdentityVerification = 'Click KYC Finish Identity Verification',
  ClickKYCSelectImageMethod = 'Click KYC Select Image Method',
  ClickKYCStartVerification = 'Click KYC Start Verification',
  ClickKYCUnderstoodVerifyingIdentity = 'Click KYC Understood Verifying Identity',
  ClickLearnHowWeCalculatePersonalImpact = 'Click Learn How We Calculate Personal Impact',
  ClickListItemChatThirdVoice = 'Click List Item Chat Third Voice',
  ClickLockedProPerk = 'Click Locked Pro Perk',
  ClickLoginEmail = 'Click Login Email',
  ClickLoginLinkEmail = 'Click Login Link Email',
  ClickLoginSignupApple = 'Click Login Signup Apple',
  ClickLoginSignupFacebook = 'Click Login Signup Facebook',
  ClickLoginSignupGoogle = 'Click Login Signup Google',
  ClickMakeAnOffer = 'Click Make An Offer',
  ClickMarkItemSold = 'Click Mark Item Sold',
  ClickMFAOTP = 'Click MFA OTP',
  ClickNavigationBar = 'Click Navigation Bar',
  ClickNotificationCard = 'Click Notification Card',
  ClickOnboardingIntentionOption = 'Click Onboarding Intention Option',
  ClickOnBoardingCountrySelection = 'Click On Boarding Country Selection',
  ClickOpenChatbot = 'Click Open Chatbot',
  ClickOpenDispute = 'Click Open Dispute',
  ClickOpenEmailInbox = 'Click Open Email Inbox',
  ClickOpenPriceBreakdown = 'Click Open Price Breakdown',
  ClickOtherProfile = 'Click Other Profile',
  ClickOtherReviews = 'Click Other Reviews',
  ClickP2PPay = 'Click P2P Pay',
  ClickP2PReceivePayment = 'Click P2P Receive Payment',
  ClickPersonalImpactStats = 'Click Personal Impact Stats',
  ClickPhone = 'Click Phone',
  ClickPlayVideo = 'Click Play Video',
  ClickPopup = 'Click Popup',
  ClickPricingSaveItemDetail = 'Click Pricing Save Item Detail',
  ClickProBanner = 'Click Pro Banner',
  ClickProInfo = 'Click Pro Info',
  ClickProSubmitForm = 'Click Pro Submit Form',
  ClickProSubscription = 'Click Pro Subscription',
  ClickProfileEditCurrentSubscription = 'Click Profile Edit Current Subscription',
  ClickReactivationShippingAllowed = 'Click Reactivation Shipping Allowed',
  ClickReactivationShippingNotAllowed = 'Click Reactivation Shipping Not Allowed',
  ClickRefuseTermsAndCommunications = 'Click Refuse Terms And Communications',
  ClickRegisterHelpCenter = 'Click Register Help Center',
  ClickRegisterResendVerification = 'Click Register Resend Verification',
  ClickRegisterVerify = 'Click Register Verify',
  ClickRejectF2FRequest = 'Click Reject F2F Request',
  ClickRejectOffer = 'Click Reject Offer',
  ClickRejectShippingRequest = 'Click Reject Shipping Request',
  ClickRemoveBundleItem = 'Click Remove Bundle Item',
  ClickReportItem = 'Click Report Item',
  ClickRepublishItemConfirm = 'Click Republish Item Confirm',
  ClickRepublishItem = 'Click Republish Item',
  ClickResendEmail = 'Click Resend Email',
  ClickResendLoginVerification = 'Click Resend Login Verification',
  ClickResendMFA = 'Click Resend MFA',
  ClickReserveItem = 'Click Reserve Item',
  ClickReviewForBuyer = 'Click Review For Buyer',
  ClickReviewForSeller = 'Click Review For Seller',
  ClickReviewSectionItemDetail = 'Click Review Section Item Detail',
  ClickSaveChangesProfile = 'Click Save Changes Profile',
  ClickSaveItemMeasures = 'Click Save Item Measures',
  ClickSaveShippingTogglePreferences = 'Click Save Shipping Toggle Preferences',
  ClickSaveChangesUserType = 'Click Save Changes User Type',
  ClickSavedSearch = 'Click Saved Search',
  ClickSearchFilters = 'Click Search Filters',
  ClickSearchSorting = 'Click Search Sorting',
  ClickSearchSuggestion = 'Click Search Suggestion',
  ClickSearch = 'Click Search',
  ClickSeeMore = 'Click See More',
  ClickSelectAmountTopUpWallet = 'Click Select Amount Top Up Wallet',
  ClickSelectBuyer = 'Click Select Buyer',
  ClickSelectCarrierOffice = 'Click Select Carrier Office',
  ClickSelectF2FDeliveryMethod = 'Click Select F2F Delivery Method',
  ClickSelectItemBumpFeaturedSliderWall = 'Click Select Item Bump Featured Slider Wall',
  ClickSelectP2PItem = 'Click Select P2P Item',
  ClickSelectP2PPaymentAmount = 'Click Select P2P Payment Amount',
  ClickSelectReserveTimeframe = 'Click Select Reserve Timeframe',
  ClickSelfServiceWebview = 'Click Self Service Webview',
  ClickSellerDetails = 'Click Seller Details',
  ClickSellerLocation = 'Click Seller Location',
  ClickSellerSectionItemDetail = 'Click Seller Section Item Detail',
  ClickSendEmailChangePassword = 'Click Send Email Change Password',
  ClickSendMessageChatbot = 'Click Send Message Chatbot',
  ClickSendOffer = 'Click Send Offer',
  ClickShippingBulkyUpload = 'Click Shipping Bulky Upload',
  ClickShippingDropOffMethods = 'Click Shipping Drop Off Methods',
  ClickShowItemStatistics = 'Click Show Item Statistics',
  ClickSignupEmail = 'Click Signup Email',
  ClickSkipMeasures = 'Click Skip Measures',
  ClickSkipOnboardingIntention = 'Click Skip Onboarding Intention',
  ClickSnackBar = 'Click Snack Bar',
  ClickStartOnboarding = 'Click Start Onboarding',
  ClickStartSelling = 'Click Start Selling',
  ClickSubscriptionAddCard = 'Click Subscription Add Card',
  ClickonaSubscriptionBenefit = 'Click on a Subscription Benefit',
  ClickSubscriptionConfirmation = 'Click Subscription Confirmation',
  ClickSubscriptionManagementPlus = 'Click Subscription Management Plus',
  ClickSubscriptionPlanDone = 'Click Subscription Plan Done',
  ClickSubscriptionSubscribe = 'Click Subscription Subscribe',
  ClickTagKeepExploring = 'Click Tag Keep Exploring',
  ClickToggleVacationMode = 'Click Toggle Vacation Mode',
  ClickTooltipProtectionPolicy = 'Click Tooltip Protection Policy',
  ClickTopProfileBadgeLink = 'Click Top Profile Badge Link',
  ClickTopUpWallet = 'Click Top Up Wallet',
  ClickTransferBankAccount = 'Click Transfer Bank Account',
  ClickTryPro = 'Click Try Pro',
  ClickAcceptTermsAndCommunications = 'Click Accept Terms And Communications',
  ClickVerificationOption = 'Click Verification Option',
  ClickVerifyOnboardingPhoneVerification = 'Click Verify Onboarding Phone Verification',
  ClickViewMyWall = 'Click View My Wall',
  ClickViewOffer = 'Click View Offer',
  ClickViewShippingToggle = 'Click View Shipping Toggle',
  ClickWallet = 'Click Wallet',
  ClickWarrantyAdditionalServicesUpload = 'Click Warranty Additional Services Upload',
  ClickWebsite = 'Click Website',
  CloseResolutionCenter = 'Close Resolution Center',
  FinishWrapped = 'Finish Wrapped',
  OpenDeeplink = 'Open Deeplink',
  OpenLink = 'Open Link',
  ScrollAppImages = 'Scroll App Images',
  ScrollCards = 'Scroll Cards',
  ScrollImages = 'Scroll Images',
  ShareWrapped = 'Share Wrapped',
  StartWrapped = 'Start Wrapped',
  VerticalScroll = 'Vertical Scroll',
  AdBlocked = 'Ad Blocked',
  AdPlacementFirstTimeVisible = 'Ad Placement First Time Visible',
  AdPlacementRequestFlowEnd = 'Ad Placement Request Flow End',
  AdPlacementRequestFlowStart = 'Ad Placement Request Flow Start',
  AdPlacementTriggeredImpression = 'Ad Placement Triggered Impression',
  CallbackDidomionReadyTime = 'Callback Didomi onReady Time',
  ClickAdBanner = 'Click Ad Banner',
  ClickAdCustomNative = 'Click Ad Custom Native',
  ClickAdPlacement = 'Click Ad Placement',
  ClickAffiliationPartner = 'Click Affiliation Partner',
  ClickIDFAConsentPopup = 'Click IDFA Consent Popup',
  ImpressionAdBidTime = 'Impression Ad Bid Time',
  ImpressionAdCreativeLoadTime = 'Impression Ad Creative Load Time',
  ImpressionAdGoogleShopping = 'Impression Ad Google Shopping',
  DetectMaliciousUserConversation = 'Detect Malicious User Conversation',
  ReceiveFirstMessageWithDistance = 'Receive First Message With Distance',
  SendFirstMessageCARS = 'Send First Message CARS',
  SendFirstMessageWithDistance = 'Send First Message With Distance',
  SendFirstMessage = 'Send First Message',
  TranslateChat = 'Translate Chat',
  ActivateShippingItemCG = 'Activate Shipping Item CG',
  ClickConfirmCloseSubscription = 'Click Confirm Close Subscription',
  ClickConfirmEditCurrentSubscription = 'Click Confirm Edit Current Subscription',
  ClickDeleteItem = 'Click Delete Item',
  ClickProDiscountDecisionPopup = 'Click Pro Discount Decision Popup',
  EditItemCar = 'Edit Item Car',
  EditItemCG = 'Edit Item CG',
  EditItemRE = 'Edit Item RE',
  ItemDeleted = 'Item Deleted',
  ItemReported = 'Item Reported',
  ReserveItemCar = 'Reserve Item Car',
  ReserveItemCG = 'Reserve Item CG',
  ReserveItemRE = 'Reserve Item RE',
  ImageLoader = 'Image Loader',
  OptimizelyInitialization = 'Optimizely Initialization',
  AnswerFeedback = 'Answer Feedback',
  AnswerWallRecommendationFeedback = 'Answer Wall Recommendation Feedback',
  CloseFeedback = 'Close Feedback',
  SkipFeedback = 'Skip Feedback',
  ClickShippingInfoItem = 'Click Shipping Info Item',
  ClickSubscriptionDirectContact = 'Click Subscription Direct Contact',
  AdUnitImpression = 'Ad Unit Impression',
  ContentComponentsImpression = 'Content Components Impression',
  ImpressionFavoriteSearchesTooltip = 'Impression Favorite Searches Tooltip',
  ImpressionTooltip = 'Impression Tooltip',
  ImpressionVerificationBanner = 'Impression Verification Banner',
  ItemCardImpression = 'Item Card Impression',
  ViewWallComponent = 'View Wall Component',
  WallComponentImpression = 'Wall Component Impression',
  WallComponentsImpression = 'Wall Components Impression',
  KYCDowngraded = 'KYC Downgraded',
  MFABankAccountEntryPoint = 'MFA Bank Account Entry Point',
  AcceptOffer = 'Accept Offer',
  CounterOffer = 'Counter Offer',
  DeclineOffer = 'Decline Offer',
  OfferCancelled = 'Offer Cancelled',
  OfferExpired = 'Offer Expired',
  Offerreceived = 'Offer received',
  ApproveLogin = 'Approve Login',
  ChangeEmailSuccess = 'Change Email Success',
  ClickUnlinkSocialAccountOption = 'Click Unlink Social Account Option',
  FirstLogin = 'First Login',
  LoginAppleSuccess = 'Login Apple Success',
  LoginEmailFailed = 'Login Email Failed',
  LoginFacebookSuccess = 'Login Facebook Success',
  LoginFailed = 'Login Failed',
  LoginGoogleSuccess = 'Login Google Success',
  LoginMailSuccess = 'Login Mail Success',
  LoginSocialFailed = 'Login Social Failed',
  LoginSuccess = 'Login Success',
  MFAApproved = 'MFA Approved',
  MFAExpired = 'MFA Expired',
  MFAFailed = 'MFA Failed',
  MFARejected = 'MFA Rejected',
  MparticleLoginError = 'Mparticle Login Error',
  MparticleLoginSuccess = 'Mparticle Login Success',
  MparticleLogoutError = 'Mparticle Logout Error',
  MparticleLogoutSuccess = 'Mparticle Logout Success',
  RegisterAppleSuccess = 'Register Apple Success',
  RegisterFacebookSuccess = 'Register Facebook Success',
  RegisterFormError = 'Register Form Error',
  RegisterGoogleSuccess = 'Register Google Success',
  RegisterMailSuccess = 'Register Mail Success',
  RegisterSuccess = 'Register Success',
  RegisterVerificationSuccess = 'Register Verification Success',
  StartUnlinkSocialAccountProcess = 'Start Unlink Social Account Process',
  StartVerificationProcess = 'Start Verification Process',
  SuspiciousLoginReported = 'Suspicious Login Reported',
  SuspiciousLogin = 'Suspicious Login',
  UnlinkSocialAccountSuccess = 'Unlink Social Account Success',
  Userbanned = 'User banned',
  VerificationSuccess = 'Verification Success',
  ClickSelectAllCatalogManager = 'Click Select All Catalog Manager',
  ItemBatchActionCatalogManager = 'Item Batch Action Catalog Manager',
  OpenWallapop = 'Open Wallapop',
  BeginFreeTrialSubscription = 'Begin Free Trial Subscription',
  BeginSubscription = 'Begin Subscription',
  EndSubscription = 'End Subscription',
  RenewalSubscription = 'Renewal Subscription',
  TranslateDispute = 'Translate Dispute',
  CameraPermission = 'Camera Permission',
  ClickActivateProItem = 'Click Activate Pro Item',
  ClickIsbnFlow = 'Click Isbn Flow',
  ClickItemAttribute = 'Click Item Attribute',
  ClickItemImage = 'Click Item Image',
  ClickUploadContinue = 'Click Upload Continue',
  ClickVoiceInput = 'Click Voice Input',
  ContinueUploadSuccessful = 'Continue Upload Successful',
  FirstListItem = 'First List Item',
  IsbnCameraPermission = 'Isbn Camera Permission',
  IsbnInput = 'Isbn Input',
  ISBNPopupClick = 'ISBN Popup Click',
  ItemRELocation = 'Item RE Location',
  ListItemCar = 'List Item Car',
  ListItemCG = 'List Item CG',
  ListItemRE = 'List Item RE',
  LoadingTimeout = 'Loading Timeout',
  ReactivateItem = 'Reactivate Item',
  SkipISBNScan = 'Skip ISBN Scan',
  UploadAttributesRetrieved = 'Upload Attributes Retrieved',
  UploadItemError = 'Upload Item Error',
  ViewIsbnNotFound = 'View Isbn Not Found',
  ViewUploadScreen = 'View Upload Screen',
  BumpError = 'Bump Error',
  BumpFlowEntryInfo = 'Bump Flow Entry Info',
  BumpPaymentError = 'Bump Payment Error',
  BumpPaymentSuccess = 'Bump Payment Success',
  ClickBumpItems = 'Click Bump Items',
  ClickBuyExtraBump = 'Click Buy Extra Bump',
  Browse = 'Browse',
  FilterError = 'Filter Error',
  ScrollSlider = 'Scroll Slider',
  SearchCatalogManagement = 'Search Catalog Management',
  SearchInOtherProfile = 'Search In Other Profile',
  Search = 'Search',
  ShareItem = 'Share Item',
  ConfirmSharePersonalInfo = 'Confirm Share Personal Info',
  Accept3DSRetryPayment = 'Accept 3DS Retry Payment',
  AcceptDispute = 'Accept Dispute',
  AcceptF2FRequest = 'Accept F2F Request',
  AcceptP2PPayment = 'Accept P2P Payment',
  AcceptShippingRequest = 'Accept Shipping Request',
  BumpPurchaseCancelled = 'Bump Purchase Cancelled',
  Cancel3DSRetryPayment = 'Cancel 3DS Retry Payment',
  CancelDispute = 'Cancel Dispute',
  CancelTransaction = 'Cancel Transaction',
  ClaimCreationSuccess = 'Claim Creation Success',
  ClaimError = 'Claim Error',
  ClickBumpConfirmation = 'Click Bump Confirmation',
  ClickPayBump = 'Click Pay Bump',
  ConfirmAcceptDispute = 'Confirm Accept Dispute',
  ConfirmItemSoldOutside = 'Confirm Item Sold Outside',
  ConfirmRefundMethod = 'Confirm Refund Method',
  ConfirmTransferBankAccount = 'Confirm Transfer Bank Account',
  CreateClaim = 'Create Claim',
  CreateDispute = 'Create Dispute',
  DAC7FormError = 'DAC7 Form Error',
  DAC7FormSuccess = 'DAC7 Form Success',
  DAC7ReportDownloadSuccess = 'DAC7 Report Download Success',
  DAC7SystemCommsSent = 'DAC7 System Comms Sent',
  DAC7SystemDataUpdated = 'DAC7 System Data Updated',
  DAC7SystemHaciendaPresentation = 'DAC7 System Hacienda Presentation',
  DAC7SystemThresholdExceeded = 'DAC7 System Threshold Exceeded',
  DAC7SystemUserBlock = 'DAC7 System User Block',
  DAC7SystemUserUnblock = 'DAC7 System User Unblock',
  DisputeCancellationSuccess = 'Dispute Cancellation Success',
  DisputeCreationSuccess = 'Dispute Creation Success',
  DisputeError = 'Dispute Error',
  EscalateDisputeSuccess = 'Escalate Dispute Success',
  EscalateDispute = 'Escalate Dispute',
  F2FPaymentSucceededBuyer = 'F2F Payment Succeeded Buyer',
  F2FPaymentSucceededSeller = 'F2F Payment Succeeded Seller',
  FirstTransactionPaymentSuccess = 'First Transaction Payment Success',
  ItemBought = 'Item Bought',
  ItemMarkSoldError = 'Item Mark Sold Error',
  ItemMarkSoldSuccess = 'Item Mark Sold Success',
  ItemSold = 'Item Sold',
  LeaveReviewForBuyer = 'Leave Review For Buyer',
  LeaveReviewForSeller = 'Leave Review For Seller',
  LocalTransactionCancelledByBuyer = 'Local Transaction Cancelled By Buyer',
  LocalTransactionCancelledBySeller = 'Local Transaction Cancelled By Seller',
  MapCarrierOfficeError = 'Map Carrier Office Error',
  MapSearchCarrierOffice = 'Map Search Carrier Office',
  PayBumpItems = 'Pay Bump Items',
  PayP2P = 'Pay P2P',
  PayReserveItem = 'Pay Reserve Item',
  PayTransaction = 'Pay Transaction',
  Payment3DSDecision = 'Payment 3DS Decision',
  Payment3DSRequired = 'Payment 3DS Required',
  PaymentUserInteractionRequired = 'Payment User Interaction Required',
  PayoutCancelled = 'Payout Cancelled',
  PayoutDelayed = 'Payout Delayed',
  RejectF2FRequest = 'Reject F2F Request',
  RejectShippingRequest = 'Reject Shipping Request',
  RequestAcceptedBuyer = 'Request Accepted Buyer',
  RequestExpiredBuyer = 'Request Expired Buyer',
  RequestExpiredSeller = 'Request Expired Seller',
  RequestRefreshedAfterDelay = 'Request Refreshed After Delay',
  RequestRejectedBuyer = 'Request Rejected Buyer',
  ReviewGivenFromBuyerToSeller = 'Review Given From Buyer To Seller',
  ReviewGivenFromSellerToBuyer = 'Review Given From Seller To Buyer',
  ReviewReceivedByBuyerFromSeller = 'Review Received By Buyer From Seller',
  ReviewReceivedBySellerFromBuyer = 'Review Received By Seller From Buyer',
  SelectAmountTopUpWalletError = 'Select Amount Top Up Wallet Error',
  ShippingDeliveredBuyer = 'Shipping Delivered Buyer',
  ShippingRequestAcceptedBuyer = 'Shipping Request Accepted Buyer',
  SubscriptionPayConfirmation = 'Subscription Pay Confirmation',
  SubscriptionPaymentFailed = 'Subscription Payment Failed',
  TopUpWalletError = 'Top Up Wallet Error',
  TopUpWallet = 'Top Up Wallet',
  TransactionCancelledBySellerBuyer = 'Transaction Cancelled By Seller Buyer',
  TransactionCancelledBySellerSeller = 'Transaction Cancelled By Seller Seller',
  TransactionCancelledWallapopBuyer = 'Transaction Cancelled Wallapop Buyer',
  TransactionCancelledWallapopSeller = 'Transaction Cancelled Wallapop Seller',
  TransactionCheckoutError = 'Transaction Checkout Error',
  TransactionDeliveredToCarrierSeller = 'Transaction Delivered To Carrier Seller',
  TransactionDeliveryExpiredBuyer = 'Transaction Delivery Expired Buyer',
  TransactionDeliveryExpiredSeller = 'Transaction Delivery Expired Seller',
  TransactionDeliveryFailedBuyer = 'Transaction Delivery Failed Buyer',
  TransactionDeliveryFailedSeller = 'Transaction Delivery Failed Seller',
  TransactionDeliveryInTransitSeller = 'Transaction Delivery In Transit Seller',
  TransactionDeliveryReturnedBuyer = 'Transaction Delivery Returned Buyer',
  TransactionDeliveryReturnedSeller = 'Transaction Delivery Returned Seller',
  TransactionPaymentFailedSeller = 'Transaction Payment Failed Seller',
  TransactionPaymentSeller = 'Transaction Payment Seller',
  TransactionPaymentSuccess = 'Transaction Payment Success',
  TransactionRequestCreatedBuyer = 'Transaction Request Created Buyer',
  TransactionRequestCreated = 'Transaction Request Created',
  TransactionReturnDeliveryFailedBuyer = 'Transaction Return Delivery Failed Buyer',
  TransactionReturnDeliveryFailedSeller = 'Transaction Return Delivery Failed Seller',
  TransactionSucceededBuyer = 'Transaction Succeeded Buyer',
  AccountDeleted = 'Account Deleted',
  CardValidationError = 'Card Validation Error',
  CardValidationSuccess = 'Card Validation Success',
  ChangeEmail = 'Change Email',
  ClickAddPromocodeTransactionPay = 'Click Add Promocode Transaction Pay',
  ClickAllowLocation = 'Click Allow Location',
  ClickApplyPromocodeTransactionPay = 'Click Apply Promocode Transaction Pay',
  ClickChangePriceTransactionPay = 'Click Change Price Transaction Pay',
  ClickClosePushPrimingScreen = 'Click Close Push Priming Screen',
  ClickDownloadDAC7Report = 'Click Download DAC7 Report',
  ClickEditItemPrice = 'Click Edit Item Price',
  ClickGenerateDAC7Report = 'Click Generate DAC7 Report',
  ClickNotNowNotifications = 'Click Not Now Notifications',
  ClickRegenerateDAC7Report = 'Click Regenerate DAC7 Report',
  SaveChangeEmail = 'Save Change Email',
  ClickSaveDAC7Form = 'Click Save DAC7 Form',
  ClickSaveEditCard = 'Click Save Edit Card',
  ClickSavePriceChangeTransactionPay = 'Click Save Price Change Transaction Pay',
  ClickScheduleHPU = 'Click Schedule HPU',
  ClickTurnOnNotifications = 'Click Turn On Notifications',
  ClickUpdateAppVersion = 'Click Update App Version',
  ConfirmVacationMode = 'Confirm Vacation Mode',
  ConsentModalClose = 'Consent Modal Close',
  ConsentModalShow = 'Consent Modal Show',
  DismissPromptDialog = 'Dismiss Prompt Dialog',
  DismissTooltip = 'Dismiss Tooltip',
  EmailFailedResetPassword = 'Email Failed Reset Password',
  EmailSentResetPassword = 'Email Sent Reset Password',
  FavoriteItemWithDistance = 'Favorite Item With Distance',
  FavoriteItem = 'Favorite Item',
  FavoriteSearch = 'Favorite Search',
  FavoriteUser = 'Favorite User',
  FinishReserveTest = 'Finish Reserve Test',
  IDFAConsentStatus = 'IDFA Consent Status',
  PendingPurchaseBannerError = 'Pending Purchase Banner Error',
  PersonalizationExclusion = 'Personalization Exclusion',
  ReactivateFavoriteSearch = 'Reactivate Favorite Search',
  RemovePersonalizationExclusion = 'Remove Personalization Exclusion',
  RemoveProSubscriptionBanner = 'Remove Pro Subscription Banner',
  ResetPasswordSuccess = 'Reset Password Success',
  SaveAddress = 'Save Address',
  SaveBankCardForm = 'Save Bank Card Form',
  SaveItemPrice = 'Save Item Price',
  SavePaymentMethodTopUp = 'Save Payment Method TopUp',
  SaveSearch = 'Save Search',
  SelectTransferAmount = 'Select Transfer Amount',
  SendAttemptF2FPushNotification = 'Send Attempt F2F Push Notification',
  SendTracer = 'Send Tracer',
  SubscriptionPaymentButtonAvailable = 'Subscription Payment Button Available',
  TracerResponseBuyer = 'Tracer Response Buyer',
  TracerResponseSeller = 'Tracer Response Seller',
  UnfavoriteItem = 'Unfavorite Item',
  UnfavoriteSearch = 'Unfavorite Search',
  UnfavoriteUser = 'Unfavorite User',
  UnsaveSearch = 'Unsave Search',
  UpdateCommercialCommunicationsConsent = 'Update Commercial Communications Consent',
  UpdateNotificationSetting = 'Update Notification Setting',
  UpdateTermsAndConditionsConsent = 'Update Terms And Conditions Consent',
  VacationModeDisabled = 'Vacation Mode Disabled',
  VacationModeEnabled = 'Vacation Mode Enabled',
  VacationModeError = 'Vacation Mode Error',
}
