export interface CategoryFieldDto {
  title: string;
  order: number;
}

export enum CATEGORY_FIELD_NAME {
  TYPE_OF_OBJECT = 'subcategory',
  TYPE_OF_OBJECT_LEVEL_2 = 'subcategory_lv2',
  BRAND = 'brand',
  MODEL = 'model',
  SIZE = 'size',
  GENDER = 'gender',
  ISBN = 'isbn',
  HEIGHT_CM = 'height_cm',
  WIDTH_CM = 'width_cm',
  LENGTH_CM = 'length_cm',
}

export interface CategoriesResponseDto {
  categories: CategoryDto[];
}
export interface CategoryDto {
  id: number;
  parent_id?: number;
  vertical_id?: string;
  name: string;
  icon?: string;
  path?: string;
  attributes: CategoryAttributesDto;
  subcategories: CategoryDto[];
  category_leaf_selection_mandatory?: boolean;
  excluded?: CATEGORY_FIELD_NAME[];
}

export const CATEGORY_FIELD_EXCLUDED_NAME = 'excluded';

export interface CategoryAttributesDto extends Partial<Record<CATEGORY_FIELD_NAME, CategoryFieldDto>> {
  [CATEGORY_FIELD_EXCLUDED_NAME]?: CATEGORY_FIELD_NAME[];
}

export interface SuggestedCategoriesResponseDto {
  categories: SuggestedCategoryDto[];
}

export interface SuggestedCategoryDto {
  id: number;
  name: string;
  path?: string;
  icon?: string;
  parent?: SuggestedCategoryDto;
  attributes?: CategoryAttributesDto;
  subcategories?: CategoryDto[];
  has_children?: boolean;
}

export interface SuggestedSubcategoriesResponseDto {
  subcategories: SuggestedSubcategoryDto[];
}

export interface SuggestedSubcategoryDto {
  id: number;
  name: string;
  path?: string;
  parent: SuggestedSubcategoryDto | null;
  has_children?: boolean;
}
