import { Observable, ReplaySubject } from 'rxjs';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { User } from '../user/user';
import { AnalyticsEvent, AnalyticsPageView, MPARTICLE_KITS, MPConfiguration, MPID, UserIdentities } from '@wallapop/analytics/constants';
import { DeviceService } from '@core/device/device.service';
import { Market, MARKET_PROVIDER } from '@configs/market.config';
import { APP_LOCALE } from '@configs/subdomains.config';
import { SessionInfoService } from '@core/session-info/session-info.service';
import { environment } from '@environments/environment';
import {
  getMParticleConfig,
  AllUserAttributes,
  initMParticleSDK,
  getUserIdentities,
  getMPID,
  setUserAttribute,
  mParticleLogin,
  getMParticleReadyCallback,
  mParticleLogout,
  trackAnalyticsEvent,
  trackAnalyticsPageView,
  AnalyticsLib,
  AnalyticsUser,
  initRudderstackAnalytics,
} from '@wallapop/analytics';
import { AppStatusService } from '@core/app-status/app-status.service';
import { WebviewManagerService } from '@core/webview-manager/webview-manager.service';

// TODO: This should not be exported. Anything that uses this should start using the getDeviceId method
export const DEVICE_ID_COOKIE_NAME = 'device_id';

export const COMMON_MPARTICLE_KITS = [MPARTICLE_KITS.BRAZE, MPARTICLE_KITS.GOOGLE_ANALYTICS4];

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private readonly _mParticleReady$: ReplaySubject<void> = new ReplaySubject<void>();

  constructor(
    private deviceService: DeviceService,
    private sessionInfoService: SessionInfoService,
    private appStatusService: AppStatusService,
    private webviewManagerService: WebviewManagerService,
    @Inject(MARKET_PROVIDER) private _market: Market,
    @Inject(LOCALE_ID) private _localeId: APP_LOCALE,
  ) {}

  public get mParticleReady$(): Observable<void> {
    return this._mParticleReady$.asObservable();
  }

  public get mParticleReadyCallback(): {
    (callback: () => void): void;
  } {
    return getMParticleReadyCallback();
  }

  public get market(): Market {
    return this._market;
  }

  public get appLocale(): APP_LOCALE {
    return this._localeId;
  }

  public getMPID(): MPID | null | undefined {
    return getMPID();
  }

  public setUserAttribute(key: string, value: string) {
    setUserAttribute(key, value);
  }

  public async initializeAnalyticsWithAuthenticatedUser(user: User): Promise<[AnalyticsLib, void]> {
    const userIdentities = getUserIdentities(user);
    const userAttributes = this.getUserAttributes();
    const mParticleLoggedConfig = await getMParticleConfig(userIdentities, userAttributes);
    const loggedMParticleKits = this.webviewManagerService.adaptKitsForWebviews([...COMMON_MPARTICLE_KITS]);

    return Promise.all([
      initRudderstackAnalytics(user, { integrations: this.webviewManagerService.getIntegrationsConfig({}) }),
      this.initializeMParticleSDK(mParticleLoggedConfig, loggedMParticleKits),
    ]);
  }

  public async initializeAnalyticsWithUnauthenticatedUser(): Promise<[AnalyticsLib, void]> {
    const userAttributes = this.getUserAttributes();
    const mParticleNotLoggedConfig = await getMParticleConfig({}, userAttributes);
    const unauthenticatedRudderstackUser: AnalyticsUser = { id: null };

    return Promise.all([
      initRudderstackAnalytics(unauthenticatedRudderstackUser, { integrations: this.webviewManagerService.getIntegrationsConfig({}) }),
      this.initializeMParticleSDK(mParticleNotLoggedConfig, this.webviewManagerService.adaptKitsForWebviews([...COMMON_MPARTICLE_KITS])),
    ]);
  }

  public trackEvent<T extends object>(event: AnalyticsEvent<T>): void {
    if (['local', 'beta'].includes(environment.name)) {
      console.log(
        `%cTRACK EVENT%c ${event.name}%c`,
        'background-color: black; color: white; border-radius: 4px; padding: 2px 4px; font-weight: bold;',
        'font-weight: bold;',
        'color: inherit; background-color: inherit;',
        event,
      );
    }
    trackAnalyticsEvent(event);
  }

  public trackPageView<T extends object>(page: AnalyticsPageView<T>): void {
    trackAnalyticsPageView(page);
  }

  public loginUser(userIdentities: UserIdentities, callback?: () => void): void {
    mParticleLogin(userIdentities, callback);
  }

  public async logoutUser(callback?: () => void): Promise<void> {
    await mParticleLogout(callback);
  }

  private getUserAttributes(): AllUserAttributes {
    return {
      deviceId: this.deviceService.getDeviceId(),
      webDeviceType: this.deviceService.getDeviceType(),
      pushOptIn: this.sessionInfoService.getPushOptInStatus(),
      market: this._market,
    };
  }

  private initializeMParticleSDK(config: MPConfiguration, kits: MPARTICLE_KITS[]): Promise<void> {
    return initMParticleSDK(config, kits)
      .then(() => this._mParticleReady$.next())
      .catch((error) => {
        this.appStatusService.addError('[Analytics Service]: Error while initializing the library', error);
      });
  }
}
