export const SEARCH_UNIFIED_QUERY_PARAM = {
  KEYWORDS: 'keywords',
  CATEGORY_ID: 'category_id',
  SUBCATEGORY_IDS: 'subcategory_ids',
  MAX_PRICE: 'max_sale_price',
  MIN_PRICE: 'min_sale_price',
  CONDITION: 'condition',
  DISTANCE_IN_KM: 'distance_in_km',
  FASHION_SIZE: 'fashion_size',
  BRAND: 'brand',
  MODEL: 'model',
  COLOR: 'color',
  IS_SHIPPABLE: 'is_shippable',
  IS_REFURBISHED: 'is_refurbished',
  LATITUDE: 'latitude',
  LONGITUDE: 'longitude',
  ORDER_BY: 'order_by',
  TIME_FILTER: 'time_filter',
  SOURCE: 'source',
  SEARCH_ID: 'search_id',
  SEARCH_FILTERS_CONTEXT: 'search_filters_context',
  CHARACTERISTICS: 'characteristics',
  TYPE: 'type',
  STORAGE_CAPACITY: 'storage_capacity',
  OPERATION: 'operation',
  BODY_TYPE: 'body_type',
  ENGINE: 'engine',
  GEARBOX: 'gearbox',
  ROOMS: 'rooms',
  BATHROOMS: 'bathrooms',
  WARRANTY: 'warranty',
  PROFESSIONAL: 'professional',
  MIN_SURFACE: 'min_surface',
  MAX_SURFACE: 'max_surface',
  MIN_YEAR: 'min_year',
  MAX_YEAR: 'max_year',
  MIN_KM: 'min_km',
  MAX_KM: 'max_km',
  MIN_SEATS: 'min_seats',
  MAX_SEATS: 'max_seats',
  MIN_NUM_DOORS: 'min_num_doors',
  MAX_NUM_DOORS: 'max_num_doors',
  MIN_HORSE_POWER: 'min_horse_power',
  MAX_HORSE_POWER: 'max_horse_power',
  SELLER_TYPE: 'seller_type',
} as const;

export const SEARCH_UNIFIED_NEXT_PAGE_PARAM = 'next_page';
